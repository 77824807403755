<template>
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <button
      class="text-brown bg-transparent border-0 text-decoration-none mr-3"
      @click="$router.push(allItems[1].to)"
    >
      <BackIcon />
      返回
    </button>
    <ol class="breadcrumb bg-transparent p-0 mb-0">
      <li
        class="breadcrumb-item"
        v-for="(item, index) in allItems"
        :key="index"
      >
        <router-link v-if="item.to" :to="item.to">{{ item.label }}</router-link>
        <span v-else>{{ item.label }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import BackIcon from './icons/BackIcon.vue'
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(
          (item) => 'label' in item && ('to' in item || 'label' in item)
        )
      }
    }
  },
  components: {
    BackIcon
  },
  computed: {
    allItems () {
      return [{ label: '主頁', to: '/' }, ...this.items]
    }
  }
}
</script>
