<template>
  <b-container fluid>
    <Breadcrumbs :items="breadcrumbItems" />

    <div class="d-flex mb-4 tab px-3">
      <RouterLink
        active-class="activeTab"
        class="tab-items pb-2 px-5"
        :to="`/loyalty-programs/customers/${this.$route.params.id}/offer`"
      >
        向客戶提供
      </RouterLink>

      <RouterLink
        active-class="activeTab"
        class="tab-items pb-2 px-5"
        :to="`/loyalty-programs/customers/${this.$route.params.id}/offer-for-birthdays`"
      >
      本月生日優惠
      </RouterLink>

      <RouterLink
        active-class="activeTab"
        class="tab-items pb-2 px-5"
        :to="`/loyalty-programs/customers/${this.$route.params.id}/history`"
      >
        已提供客戶
      </RouterLink>

      <RouterLink
        active-class="activeTab"
        class="tab-items pb-2 px-5"
        :to="`/loyalty-programs/customers/${this.$route.params.id}/customer-using-coupon`"
      >
        已使用的客戶
      </RouterLink>
    </div>

    <router-view></router-view>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'Customers',
  data: () => ({
    tab: 1,
    breadcrumbItems: [
      { label: '優惠券管理', to: '/loyalty-programs' },
      { label: '向客戶提供' }
    ]
  }),
  components: {
    Breadcrumbs
  }
}
</script>

<style>
.activeTab {
  border-bottom: 5px solid #6b5045;
  color: #6b5045;
}

.tab { position: relative; }

.tab::before {
  position: absolute;
  content: "";
  width: 98%;
  bottom: 2px;
  border-bottom: 1px solid #6b5045;
}

.tab-items {
  font-weight: bold;
  cursor: pointer;
  color: #6b5045;
}

.tab-items:hover {
  color: #6b5045;
  text-decoration: none;
}
</style>
